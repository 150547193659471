<template>
  <div>
    <h3 class="section__header">
      {{ $t('schedule.simulation.simulationKpiResult') }}
      <div
        class="section__collapse-controller"
        @click="toggleCollapse"
      >
        {{ isCollapse ? $t('schedule.base.openCollapseItem') : $t('schedule.base.closeCollapseItem') }}
        <i class="icon-controller el-icon-arrow-down" />
      </div>
    </h3>
    <spinner v-if="isLoading" />
    <div
      v-else
      class="section__content"
    >
      <bar-chart
        v-for="(data, index) in kpiData"
        :key="index"
        :chart-data="data"
      />
    </div>
  </div>
</template>

<script>
import BarChart from '@/schedule/components/chart/BarChart'
import { getKpiResult } from '@/schedule/API/Simulation'
import { mapState } from 'vuex'

export default {
  name: 'KpiCompare',
  components: {
    BarChart
  },
  data () {
    return {
      isLoading: true,
      isCollapse: false,
      kpiData: []
    }
  },
  computed: {
    ...mapState('simulation', ['planId']),
    kpiDataTemplate () {
      return [
        { title: 'capacity', series: [], yAxisName: this.$t('schedule.simulation.count') },
        { title: 'ofr', series: [], yAxisName: this.$t('schedule.simulation.percentage') },
        { title: 'cycleTime', series: [], yAxisName: this.$t('schedule.simulation.dayCount') },
        { title: 'utilization', series: [], yAxisName: this.$t('schedule.simulation.percentage') }
      ]
    }
  },
  mounted () {
    this.fetchKpiResult()
  },
  methods: {
    fetchKpiResult () {
      this.isLoading = true
      this.kpiData = JSON.parse(JSON.stringify(this.kpiDataTemplate))
      getKpiResult(this.planId)
        .then(solutionKpiList => {
          if (solutionKpiList.length === 0) return
          solutionKpiList.forEach(s => {
            this.kpiData[0].series.push(s.capacity)
            this.kpiData[1].series.push(Number.parseFloat(s.ofr * 100).toFixed(2))
            this.kpiData[2].series.push(s.cycleTime)
            this.kpiData[3].series.push(Number.parseFloat(s.utilization * 100).toFixed(2))
          })
          this.kpiData.forEach(t => {
            t.xAxis = solutionKpiList.map(solution => this.$t('schedule.simulation.plan') + solution.solutionSeq.toString().replace('.', '-'))
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
      this.$emit('toggle-collapse', this.isCollapse)
    }
  }
}
</script>

<style lang="scss" scoped>
.page__section {
  border-top: 1px solid var(--color-border);
  flex: 0 0 342px;
  padding: 24px;
  transition: all 0.3s ease;

  .section {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      margin-top: 0;
      padding-bottom: 12px;
      position: relative;
    }

    &__content {
      display: flex;

      .card {
        flex: 1;
      }
    }

    &__collapse-controller {
      color: var(--color-text);
      cursor: pointer;
      font-size: 12px;

      .icon-controller {
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
}

.is-kpi-collapse {
  .page__section {
    flex: 0 0 50px;

    .section__header {
      padding-bottom: 0;
    }

    .section__content {
      display: none;
    }
  }

  .icon-controller {
    transform: rotate(180deg);
  }
}
</style>
