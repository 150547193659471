<template>
  <section class="dialog">
    <el-progress
      :percentage="progress"
      :stroke-width="3"
      :width="80"
      color="#2AD2E2"
      type="circle"
    />
    <div class="dialog__title">
      {{ $t('schedule.simulation.simulating') }}
    </div>
    <schedule-button
      type="outline"
      @click="$emit('cancel')"
    >
      {{ $t('schedule.button.cancel') }}
    </schedule-button>
  </section>
</template>

<script>
export default {
  name: 'SimulatingSpinner',
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    color: var(--color-theme);
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  ::v-deep .el-progress {
    svg path:first-child {
      stroke: #666;
    }

    &__text {
      color: #fff;
    }
  }
}
</style>
