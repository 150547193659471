<template>
  <div>
    <div class="schedule__header">
      <span class="schedule__header-description">
        {{ $t('schedule.schedule.ganttInfo') }}
      </span>
      <div class="schedule__header-legend">
        <job-status-legend :job-states="jobStates" />
      </div>
      <div class="schedule__header-select">
        <span class="schedule__header-select-label">
          {{ $t('schedule.simulation.scheduleResult.viewScale') }}
        </span>
        <schedule-select
          v-model="scale"
          :options="scaleList"
        />
      </div>
    </div>
    <gantt-chart
      :current-solution-id="currentSolutionId"
      :restrictions="restrictions"
      :scale="scale"
      @click-schedule-item="$emit('click-schedule-item', $event)"
    />
  </div>
</template>

<script>
import JobStatusLegend from '@/schedule/components/JobStatusLegend'
import GanttChart from '@/schedule/components/chart/gantt/GanttChart'
import { JOB_STATUS } from '@/schedule/utils/enum'

export default {
  components: {
    JobStatusLegend,
    GanttChart
  },
  props: {
    currentSolutionId: {
      type: Number,
      default: null
    },
    restrictions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      scale: 720
    }
  },
  computed: {
    scaleList () {
      return [
        {
          label: this.$t('schedule.simulation.scheduleResult.halfDay'),
          value: 60
        },
        {
          label: 1 + this.$t('schedule.simulation.scheduleResult.day'),
          value: 120
        },
        {
          label: 3 + this.$t('schedule.simulation.scheduleResult.day'),
          value: 360
        },
        {
          label: 7 + this.$t('schedule.simulation.scheduleResult.day'),
          value: 720
        },
        {
          label: 14 + this.$t('schedule.simulation.scheduleResult.day'),
          value: 1440
        }
      ]
    },
    jobStates () {
      return [
        JOB_STATUS.SCHEDULED,
        JOB_STATUS.OVERDUE,
        JOB_STATUS.UNSCHEDULED
      ]
    }
  }
}
</script>
