<template>
  <spinner
    v-if="isSubmitting"
    class="page__spinner"
  />
  <div
    v-else
    :class="{'is-kpi-collapse': isKpiCollapse}"
    class="page"
  >
    <div class="page__section detail">
      <div class="section__side-nav">
        <div class="section__title">
          {{ $t('schedule.simulation.solution') }}
        </div>
        <div
          v-if="currentSolution"
          class="solution__list"
        >
          <solution-card
            v-for="solution in solutions"
            :key="solution.solutionId"
            :solution="solution"
            :current-solution-sequence="currentSolution.sequence"
            :solution-failed-judger="isSolutionFailed"
            @click-card="switchSolution"
          />
        </div>
      </div>
      <div
        v-if="currentSolutionSequence"
        class="section__main"
      >
        <div class="section__header">
          <h2 class="section__title">
            {{ $t('schedule.simulation.title') }}
            <jobs-filter
              :job-states-options="jobStates"
              @submit="updateRestrictions"
            />
          </h2>
          <div class="section__header--actions">
            <schedule-button
              size="m"
              type="outline"
              @click="onClickBackToSetting"
            >
              {{ $t('schedule.simulation.backToSetting') }}
            </schedule-button>
            <schedule-button
              size="m"
              class="adopt-button"
              @click="adoptPlan"
            >
              {{ $t('schedule.simulation.adoptPlan') }}
            </schedule-button>
          </div>
        </div>
        <schedule-tab
          :active-tab="resultType"
          class="page__tabs"
          :tabs="resultTypeTabs"
          @switch="switchTab($event)"
        />
        <keep-alive :max="10">
          <schedule-data-table
            v-if="resultType === SOLUTION_RESULT_TYPE.DELIVERY"
            :key="`delievery-table-${currentSolutionId}`"
            :remote-method="getDeliverySimulateResult"
            :params="{planId, solutionId: currentSolutionId}"
            :data-row-transfer="deliveryDataRowTransfer"
            :table-header-list="deliveryTableHeaderList"
            :restrictions="restrictions"
          />
          <schedule-data-table
            v-else-if="resultType === SOLUTION_RESULT_TYPE.DETAIL"
            :key="`detail-table-${currentSolutionId}`"
            :remote-method="getDetailSimulateResult"
            :params="{planId, solutionId: currentSolutionId}"
            :table-header-list="detailTableHeaderList"
            :restrictions="restrictions"
          />
          <detail-gantt
            v-else-if="resultType === SOLUTION_RESULT_TYPE.GANTT"
            :key="`gantt-${currentSolutionId}`"
            :restrictions="restrictions"
            :current-solution-id="currentSolutionId"
            @click-schedule-item="onClickGanttBar"
          />
          <solution-compare
            v-else-if="resultType === SOLUTION_RESULT_TYPE.WITHIN_SCHEDULE_COMPARE"
            :key="`within-schedule-compare-${currentSolutionId}`"
            :solution="currentSolution"
          />
          <adjust-history
            v-else-if="resultType === SOLUTION_RESULT_TYPE.ADJUST_HISTORY"
            :key="`adjust-history-${currentSolutionId}`"
            :sequence="currentSolutionSequence"
            @check="checkAdjustHistory"
          />
        </keep-alive>
      </div>
      <spinner v-else />
    </div>
    <kpi-compare
      class="page__section kpi"
      :key="kpiComponentKey"
      @toggle-collapse="isKpiCollapse = $event"
    />
    <!-- dialogs / snack bars -->
    <adjust-job-dialog
      v-if="isShowAdjustJobDialog"
      :job-info="currentAdjustJob"
      :adjusted-job-info="adjustedJobInfo"
      :schedule-info="currentSolution"
      :is-view-mode="isViewAdjustHistory"
      @close="closeAdjustJobDialog"
      @confirm="onConfirmAdjustments"
    />
    <adjust-job-snack-bar
      v-show="Object.values(adjustedJobs).length > 0"
      ref="adjustJobSnackBar"
      :sequence="currentAdjustSolutionSeq"
      @re-adjust="setCurrentAdjustJobData"
      @simulate="startSimulateChildSolution"
      @hard-cancel="clearSolutionAdjustment"
    />
    <clear-adjustment-confirm-dialog
      v-if="isShowConfirmResetAdjustDialog"
      ref="clearAdjustmentsConfirmDialog"
      :title="clearAdjustmentConfirmDialogTitle"
      :sequence="currentAdjustSolutionSeq"
    />
    <simulating-dialog
      v-if="isSimulatingDialogOpen"
      :current-solution="currentSolution"
      @finish="onFinishSimulateChildSolution"
      @cancel="cancelSimulation"
    />
  </div>
</template>

<script>
import { adoptionSolution, getDeliverySimulateResult, getDetailSimulateResult } from '@/schedule/API/Simulation'
import GanttChart from '@/schedule/components/chart/gantt/GanttChart'
import SolutionCard from '../components/SolutionCard'
import JobsFilter from '@/schedule/components/JobsFilter'
import AdjustJobDialog from './components/adjustJob/AdjustJobDialog'
import AdjustJobSnackBar from './components/adjustJob/AdjustJobSnackBar'
import ClearAdjustmentConfirmDialog from './components/adjustJob/ClearAdjustmentConfirmDialog'
import SimulatingDialog from './components/adjustJob/SimulatingDialog'
import ScheduleDataTable from '@/schedule/components/table/ScheduleDataTable'
import DetailGantt from './components/detail/DetailGantt'
import SolutionCompare from './components/solutionCompare/SolutionCompare'
import AdjustHistory from './components/adjustJob/AdjustHistory'
import KpiCompare from './components/kpi/KpiCompare'
import ScheduleTab from '@/schedule/components/ScheduleTab'
import { JOB_STATUS, SIMULATION_RESULT, SOLUTION_RESULT_TYPE } from '@/schedule/utils/enum'
import { deliveryDataRowTransfer } from '@/schedule/utils/dataRowTransfer'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SimulationResult',
  components: {
    GanttChart,
    SolutionCard,
    JobsFilter,
    AdjustJobDialog,
    AdjustJobSnackBar,
    SimulatingDialog,
    ScheduleDataTable,
    DetailGantt,
    SolutionCompare,
    AdjustHistory,
    KpiCompare,
    ScheduleTab,
    ClearAdjustmentConfirmDialog
  },
  data () {
    return {
      isSubmitting: false,
      isShowConfirmResetAdjustDialog: false,
      isSimulatingDialogOpen: false,
      isKpiCollapse: false,
      isViewAdjustHistory: false,
      resultType: SOLUTION_RESULT_TYPE.DELIVERY,
      restrictions: {},
      currentSolutionSequence: null,
      currentAdjustSolutionSeq: null,
      currentAdjustJob: null,
      kpiComponentKey: 1,
      compareInfo: {
        parentWithoutSchedule: 0,
        childWithoutSchedule: 0
      },
      clearAdjustmentConfirmDialogTitle: '',
      adjustJobSnackBarRef: null,
      SOLUTION_RESULT_TYPE,
      getDeliverySimulateResult,
      getDetailSimulateResult
    }
  },
  computed: {
    ...mapState('simulation', ['planId', 'solutions']),
    ...mapState('jobAdjustments', ['adjustedJobs', 'adjustedHistory']),
    ...mapGetters('scheduleSetting', ['deliveryTableHeaderList', 'detailTableHeaderList']),
    currentSolution () {
      if (!this.currentSolutionSequence) return undefined

      let solutions = []
      let solution = null

      if (this.isParentSolution(this.currentSolutionSequence)) {
        solutions = this.solutions
      } else {
        const parentSolutionSequence = Math.floor(this.currentSolutionSequence)
        const parentSolution = this.solutions.find(item => item.sequence === parentSolutionSequence)
        solutions = parentSolution.children.map(childSolution => {
          return {
            parentSolutionId: parentSolution.solutionId,
            ...parentSolution,
            ...childSolution
          }
        })
      }
      for (let i = 0; i < solutions.length; i++) {
        if (solutions[i].sequence === this.currentSolutionSequence) {
          solution = solutions[i]
          break
        }
      }
      return solution
    },
    currentSolutionId () {
      if (!this.currentSolution) return undefined
      return this.currentSolution.solutionId
    },
    adjustedJobInfo () {
      if (this.isViewAdjustHistory) {
        // 檢視已經模擬過的子方案的調整紀錄
        return (this.adjustedHistory[this.currentSolutionSequence] && this.adjustedHistory[this.currentSolutionSequence][this.currentAdjustJob.jobName])
      } else if (Object.values(this.adjustedJobs).length > 0 && this.currentAdjustJob) {
        // 檢視尚未模擬過的子方案的調整紀錄
        return this.adjustedJobs[this.currentAdjustJob.jobName]
      }
      return null
    },
    hasChildSolution () {
      let result = false
      for (let i = 0; i < this.solutions.length; i++) {
        if (this.solutions[i].children) {
          result = true
          break
        }
      }
      return result
    },
    resultTypeTabs () {
      if (!this.currentSolution) return []
      return [
        {
          type: SOLUTION_RESULT_TYPE.DELIVERY,
          name: this.$t('schedule.schedule.jobDeliver')
        },
        {
          type: SOLUTION_RESULT_TYPE.DETAIL,
          name: this.$t('schedule.schedule.jobSchedule')
        },
        {
          type: SOLUTION_RESULT_TYPE.GANTT,
          name: this.$t('schedule.simulation.schedule')
        },
        ...(this.currentSolution.isChild
          ? [
            {
              type: SOLUTION_RESULT_TYPE.WITHIN_SCHEDULE_COMPARE,
              name: this.$t('schedule.solutionCompare.name')
            },
            {
              type: SOLUTION_RESULT_TYPE.ADJUST_HISTORY,
              name: this.$t('schedule.simulation.adjustJob.adjustList')
            }
          ]
          : []
        )
      ]
    },
    jobStates () {
      return [
        JOB_STATUS.SCHEDULED,
        JOB_STATUS.OVERDUE,
        JOB_STATUS.UNSCHEDULED
      ]
    },
    isShowAdjustJobDialog () {
      return !!this.currentAdjustJob
    }
  },
  mounted () {
    // 找出第一個 演算成功 的方案
    const completedSolutions = this.solutions.filter(solution => {
      return !this.$store.state.simulation.simulationResult.failedSolutionIds.includes(solution.solutionId)
    })
    this.currentSolutionSequence = completedSolutions[0].sequence
    this.adjustJobSnackBarRef = this.$refs.adjustJobSnackBar
  },
  destroyed () {
    if (Object.values(this.adjustedJobs).length > 0) this.$store.commit('jobAdjustments/clearAdjustedJobs')
  },
  methods: {
    async onClickGanttBar (scheduleItem) {
      // 排除機台預定事件
      if (scheduleItem.reason) return
      // 調整工單只能針對第一層方案
      if (this.currentSolution.isChild) return
      if (
        // 當前有子模擬，欲開啟另一個子模擬，要跳提示
        Object.keys(this.adjustedJobs).length > 0 &&
        this.currentAdjustSolutionSeq &&
        this.currentAdjustSolutionSeq !== this.currentSolution.sequence
      ) {
        const title = this.$t('schedule.simulation.adjustJob.restartAdjustmentsConfirm', { solutionSequence: this.currentAdjustSolutionSeq })
        this.openClearAdjustmentsConfirmDialog(title)
          .then(() => {
            this.clearSolutionAdjustment()
            this.currentAdjustSolutionSeq = this.currentSolution.sequence
            this.setCurrentAdjustJobData(scheduleItem)
          })
          .finally(() => {
            this.isShowConfirmResetAdjustDialog = false
            this.clearAdjustmentConfirmDialogTitle = ''
          })
      } else {
        this.currentAdjustSolutionSeq = this.currentSolution.sequence
        this.setCurrentAdjustJobData(scheduleItem)
      }
    },
    openClearAdjustmentsConfirmDialog (title) {
      this.clearAdjustmentConfirmDialogTitle = title
      this.isShowConfirmResetAdjustDialog = true
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          this.$refs.clearAdjustmentsConfirmDialog.$on('confirm', () => resolve())
          this.$refs.clearAdjustmentsConfirmDialog.$on('cancel', () => reject())
        })
      })
    },
    setCurrentAdjustJobData (scheduleItem) {
      this.isViewAdjustHistory = false
      this.currentAdjustJob = {
        jobName: scheduleItem.job || scheduleItem.jobName,
        jobId: scheduleItem.jobId,
        jobStartTime: scheduleItem.jobStartTime
      }
    },
    startSimulateChildSolution () {
      this.isSimulatingDialogOpen = true
    },
    onFinishSimulateChildSolution ({ result, childSequence = null }) {
      // TODO 待確認調整失敗訊息和呈現方式
      if (result === SIMULATION_RESULT.COMPLETED) {
        this.currentSolutionSequence = childSequence
        this.resultType = SOLUTION_RESULT_TYPE.DELIVERY,
        this.kpiComponentKey += 1
        // 將調整項目暫存以來，以供後續檢視
        this.$store.commit('jobAdjustments/setAdjustedHistory', {
          childSequence,
          adjustHistory: this.adjustedJobs
        })
      }
      this.clearSolutionAdjustment()
      this.isSimulatingDialogOpen = false
    },
    cancelSimulation () {
      this.isSimulatingDialogOpen = false
    },
    onConfirmAdjustments ($event) {
      if (this.adjustJobSnackBarRef.restoreTimer.length > 0) this.hardCancelPreviosAdjustment()
      this.$store.commit('jobAdjustments/setAdjustedJobsByJobName', $event)
      this.closeAdjustJobDialog()
    },
    hardCancelPreviosAdjustment () {
      this.adjustJobSnackBarRef.resetCanceling()
      this.clearSolutionAdjustment()
    },
    closeAdjustJobDialog () {
      if (this.isViewAdjustHistory) this.isViewAdjustHistory = false
      this.currentAdjustJob = null
    },
    clearSolutionAdjustment () {
      this.$store.commit('jobAdjustments/clearAdjustedJobs')
    },
    switchSolution (solution) {
      if (this.isSolutionFailed(solution.solutionId)) return
      if (this.currentSolutionSequence === solution.sequence) return
      this.resultType = SOLUTION_RESULT_TYPE.DELIVERY
      this.currentSolutionSequence = solution.sequence
    },
    switchTab (type) {
      if (this.resultType === type) return
      this.resultType = type
    },
    adoptPlan () {
      this.isSubmitting = true
      adoptionSolution(this.planId, this.currentSolution.solutionId)
        .then(() => {
          this.clearSimulationProgress()
          this.$store.commit('simulation/setSolutions', [])
          this.$router.push({ name: 'CurrentSimulation' })
        })
        .finally(() => { this.isSubmitting = false })
    },
    onClickBackToSetting () {
      if (this.hasChildSolution) {
        const title = this.$t('schedule.simulation.adjustJob.clearChildrenSolutionConfirm')
        this.openClearAdjustmentsConfirmDialog(title)
          .then(() => this.backToSetting())
          .catch(() => this.isShowConfirmResetAdjustDialog = false)
          .finally(() => this.clearAdjustmentConfirmDialogTitle = '')
      } else {
        this.backToSetting()
      }
    },
    backToSetting () {
      // 從模擬結果返回模擬設定，會清除當前任何模擬進度
      this.clearSimulationProgress()
      this.$router.push({ name: 'SimulationSetting' })
    },
    clearSimulationProgress () {
      this.$store.commit('simulation/setPlanId', null)
      this.$store.commit('simulation/removeAllChildrenSolution')
      this.$store.commit('jobAdjustments/clearAdjustedJobs')
      this.$store.commit('jobAdjustments/clearAdjustedHistory')
    },
    isSolutionFailed (solutionId) {
      return this.$store.state.simulation.simulationResult.failedSolutionIds.includes(solutionId)
    },
    updateRestrictions (newVal) {
      this.restrictions = newVal
    },
    isParentSolution (sequence) {
      // sequence 為整數代表為 parent solution
      return Number.isInteger(sequence)
    },
    checkAdjustHistory (jobName) {
      this.isViewAdjustHistory = true
      this.currentAdjustJob = this.adjustedHistory[this.currentSolutionSequence][jobName]
    },
    deliveryDataRowTransfer
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__spinner {
    margin-top: 30vh;
  }

  &__section {
    &.detail {
      display: flex;
      flex: 1;
      height: 0;

      .section {
        &__side-nav {
          display: flex;
          flex-direction: column;
          padding: 24px;
          width: 250px;

          .section__title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 12px;
          }

          .solution__list {
            overflow: auto;

            .card-like {
              height: auto;
              padding: 16px 0 16px 16px;
            }
          }
        }

        &__header {
          display: flex;
          justify-content: space-between;
          padding-bottom: 12px;
          position: relative;

          .section__title {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
          }

          &--actions {
            position: absolute;
            right: 0;
            top: calc(100% + 8px);

            .default-button + .default-button {
              margin-left: 8px;
            }
          }
        }

        &__main {
          border-left: 1px solid var(--color-border);
          display: flex;
          flex: 1;
          flex-direction: column;
          min-width: 0;
          overflow: auto;
          overflow: overlay;
          padding: 24px;

          .spinner {
            height: calc(100% - 40px);
          }

          .schedule-gantt-chart {
            flex: 1;
          }

          .pagination-table {
            flex: 1;
            height: 0;

            ::v-deep .el-table {
              height: 100%;
            }
          }
        }

        &__tabs {
          margin-bottom: 15px;
        }
      }

      ::v-deep .schedule {
        &__header {
          align-items: center;
          display: flex;
          font-size: 14px;
          justify-content: space-between;

          &-description {
            margin-right: 16px;
          }

          &-select {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: flex-end;

            &-label {
              margin-right: 4px;
            }

            .schedule-select {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }

  .section {
    &__side-nav {
      display: flex;
      flex-direction: column;
      padding: 24px;
      width: 250px;

      .section__title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 12px;
      }

      .solution__list {
        overflow: auto;
      }
    }

    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    &__main {
      border-left: 1px solid var(--color-border);
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 0;
      padding: 24px;

      .section__title {
        margin-bottom: 0;
        margin-top: 0;
      }

      .spinner {
        height: calc(100% - 40px);
      }

      .schedule-gantt-chart {
        flex: 1;
      }

      .pagination-table {
        flex: 1;

        ::v-deep .el-table {
          height: 100%;
        }
      }
    }

    &__tabs {
      margin-bottom: 15px;
    }

    .adopt-button {
      position: absolute;
      right: 0;
      top: calc(100% + 8px);
    }

    .schedule {
      &__header {
        align-items: center;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
      }
    }
  }

  ::v-deep .el-tabs {
    margin-bottom: 12px;
    width: 100%;

    & > .el-tabs__header {
      border: none;
      margin: 0;
      width: calc(100% - 190px);

      .el-tabs__nav {
        border: none;
        overflow-x: auto;
        position: relative;
        width: 100%;

        &::before {
          background: #324b4e;
          bottom: 0;
          content: '';
          height: 3px;
          position: absolute;
          width: 100%;
        }
      }

      .el-tabs__item {
        border: none;
        border-bottom: 3px solid #324b4e;
        color: #aaa;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: top;
        white-space: nowrap;
        width: 160px;

        &.is-active {
          background: linear-gradient(360deg, #324b4e 0%, rgba(50, 75, 78, 0) 100%);
          border-bottom: 3px solid $theme-color-primary;
          color: #fff;
        }
      }
    }
  }
}

</style>
