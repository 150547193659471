import request from '@/schedule/utils/publicRequest.js'
import store from '@/store'

export function getJobList ({
  projectId,
  fetchAll = false,
  page = 0,
  size = 20,
  scheduled,
  delayed,
  withinScheduleTime,
  keyword = null,
  deadlineStartDate,
  deadlineEndDate,
  // YK 在使用的舊參數
  startDate,
  endDate,
  orderNumber
}) {
  return request({
    url: `/${store.getters['scheduleSetting/isYKSchedule'] ? 'order' : 'job'}/list`,
    method: 'GET',
    params: {
      projectId,
      fetchAll,
      page,
      size,
      keyword,
      deadlineStartDate,
      deadlineEndDate,
      scheduled,
      delayed,
      withinScheduleTime,
      // YK 在使用的舊參數
      startDate,
      endDate,
      orderNumber
    }
  })
}

export function getJobInfo ({ solutionId, jobId }) {
  return request({
    url: '/job/info',
    method: 'GET',
    params: {
      solutionId,
      jobId
    }
  })
}
