<template>
  <div
    :class="{
      'is-adjusting': innerAdjustedJobsLength > 0,
      'is-closed': isHideJobList
    }"
    class="adjusted-jobs__wrapper"
  >
    <div class="adjusted-jobs__action-bar">
      <div
        class="adjusted-jobs__action-bar-left"
        @click="isHideJobList = !isHideJobList"
      >
        <i18n path="schedule.simulation.adjustJob.solutionAdjustedSummary">
          <span>{{ sequence }}</span>
          <span class="highlight">{{ innerAdjustedJobsLength }}</span>
        </i18n>
        <svg-icon
          icon-class="arrow-right"
          class="adjusted-jobs__collapse-arrow"
        />
      </div>
      <div class="adjusted-jobs__action-bar-right">
        <template v-if="isShowRestoreButton">
          {{ $t('schedule.simulation.adjustJob.youHaveCanceledAdjustments') }}
          <schedule-button
            type="outline"
            @click="resetCanceling"
          >
            {{ $t('schedule.button.restore') }}
          </schedule-button>
        </template>
        <template v-else>
          {{ $t('schedule.simulation.adjustJob.confirmAdoptAdjustments') }}？
          <schedule-button
            type="outline"
            @click="softCancel"
          >
            {{ $t('schedule.button.cancel') }}
          </schedule-button>
          <schedule-button @click="$emit('simulate')">
            {{ $t('schedule.simulation.startSimulation') }}
          </schedule-button>
        </template>
      </div>
    </div>
    <div class="adjusted-jobs__list">
      <span
        v-for="(jobInfo, index) in innerAdjustedJobs"
        :key="jobInfo.jobId"
        class="job-name"
        @click="$emit('re-adjust', jobInfo)"
      >
        <span class="job-name__label">{{ jobInfo.jobName }}</span>{{ index === innerAdjustedJobs.length -1 ? '' : ', ' }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AdjustJobSnackBar',
  props: {
    sequence: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isHideJobList: false,
      isShowRestoreButton: false,
      restoreTimer: []
    }
  },
  computed: {
    ...mapState('jobAdjustments', ['adjustedJobs']),
    innerAdjustedJobs () {
      return Object.values(this.adjustedJobs).sort((a, b) => a.lastEditedTimestamp - b.lastEditedTimestamp)
    },
    innerAdjustedJobsLength () {
      return this.innerAdjustedJobs.length
    }
  },
  methods: {
    resetCanceling () {
      this.clearSoftCancelRestoreTimer()
      this.isShowRestoreButton = false
    },
    softCancel () {
      this.isShowRestoreButton = true
      this.restoreTimer.push(setTimeout(() => {
        this.resetCanceling()
        this.$emit('hard-cancel')
      }, 5 * 1000))
    },
    clearSoftCancelRestoreTimer () {
      this.restoreTimer.forEach(timer => clearTimeout(timer))
      this.restoreTimer = []
    }
  }
}
</script>

<style lang="scss" scoped>
$snack-bar-height: 52px;

.adjusted-jobs {
  &__wrapper {
    background-color: rgba(46, 49, 50, 0.95);
    bottom: 0;
    left: $app-side-nav-closed-width;
    position: fixed;
    transform: translateY(100%);
    transition: all 0.3s ease;
    width: calc(100% - #{$app-side-nav-closed-width});
    z-index: 1000;

    &.is-adjusting {
      transform: translateY(0);
    }

    &.is-closed {
      transform: translateY(calc(100% - #{$snack-bar-height}));
    }

    .link {
      cursor: pointer;
    }
  }

  &__action-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;

    &-left {
      cursor: pointer;

      .highlight {
        color: $theme-color-warning;
      }
    }

    &-right {
      .default-button + .default-button {
        margin-left: 8px;
      }
    }
  }

  &__collapse-arrow {
    transform: rotate(90deg);
    transition: transform 0.3s ease;

    .is-closed & {
      transform: rotate(-90deg);
    }
  }

  &__list {
    font-size: 14px;
    max-height: 30vh;
    overflow: auto;
    overflow: overlay;
    padding: 4px 24px 16px;

    .job-name {
      margin-right: 8px;

      &__label {
        border-bottom: 1px solid #fff;
        cursor: pointer;
        position: relative;
        white-space: nowrap;

        &:hover {
          border-bottom: 1px solid $theme-color-primary;
          color: $theme-color-primary;
        }
      }
    }
  }
}
</style>
